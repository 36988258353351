import { useState, useContext } from "react"
import { ICarePerson } from "../../../CarePersonPage/types"
import { CarePersonNearMeCard } from "../../../ui"
import { LocationPageContext } from "../../LocationPageProvider"
import Pagination from "../../../Pagination"
import { Container } from "./styled"

export const CarePersonsList = () => {
  const { carePersons } = useContext(LocationPageContext)
  const carePersonsPerPage = 30
  const [currentCarePersons, setCurrentCarePersons] =
    useState<ICarePerson[]>(carePersons)

  const updatePagePost = (page: number) => {
    const lastPost = Math.min(
      page * carePersonsPerPage + carePersonsPerPage,
      carePersons.length
    )
    const pagePosts = [...carePersons].slice(
      page * carePersonsPerPage,
      lastPost
    )
    setCurrentCarePersons(pagePosts)
  }

  return (
    <Container>
      {currentCarePersons.map((carePerson, i) => (
        <CarePersonNearMeCard
          carePerson={carePerson}
          key={`care-person-card-${i}`}
        />
      ))}
      <Pagination
        itemsCount={carePersons.length}
        itemsPerPage={30}
        onPageChange={page => {
          updatePagePost(page)
        }}
      />
    </Container>
  )
}
